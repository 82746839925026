import { ActionColumn } from "./ActionColumn";
import { AdvisorColumn } from "./AdvisorColumn";
import { StatusColumn } from "./StatusColumn";
import { HideableCell } from "./HideableCell";
import { LabelColumn } from "./LabelColumn";
import { HighlightTextTypography } from "../../components/AdvisorCard/HighlightText";
import { Typography, IconButton, Tooltip } from "@alphasights/alphadesign-components";
import { withLoginWall } from "components/LoginWall/LoginWall";
import { CidBadge } from "pages/InteractionPage/sections/InteractionHeader/InteractionHeader";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { getRolePeriodDuration } from "helpers/displayHelpers";
import { MessageStatusColumn } from "./MessageStatusColumn";
import { useMessageThreadContext } from "pages/MessengerPage/context/MessageThreadContext";
import { useLabelsContext } from "providers/LabelsProvider";
import { priority } from "providers/MessengerProvider";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Advisor } from "@alphasights/alphadesign-icons";
import { useCommentContext } from "components/CommentThread/CommentContext";
import { NewCommentIcon } from "./Overlays";
import { MessageThreadHeaderResponse } from "types";
import { ExpertTableColumnDefinition, ExpertTableRow } from "models/ExpertTable";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { RelevantCompanyColumn } from "./RelevantCompanyColumn";
import { InteractionDetailsColumn } from "./InteractionDetailsColumn";
import { useFlags } from "app/wrappers/FeatureFlagsWrapper";
import { ProjectFeature } from "@alphasights/portal-api-client";
import { useCurrentUser, useMyProjects } from "@alphasights/portal-auth-react";
import { ENABLE_PORTAL_COMMENTS, useProjectBadgeContext } from "providers/BadgeProvider";

const statusOrder = ["available", "requested", "scheduled", "completed"];

const useBuildColumnDefinitions = ({
  interactions,
  onSelectCard,
  preferencesEnabled,
  project,
  userCidEnabled,
  origin,
}: {
  interactions: Interaction[];
  onSelectCard: (interaction: Interaction) => void;
  preferencesEnabled: boolean;
  project: Project;
  userCidEnabled: boolean;
  origin?: string;
}): { [key: string]: ExpertTableColumnDefinition } => {
  preferencesEnabled = preferencesEnabled && project.active;
  const { getWorkRequestStatus } = useMessageThreadContext();
  const { getLabelledExpert } = useLabelsContext();
  const { isMobile } = useCheckScreen();
  const { isFeatureDisabled } = useCurrentProjectContext();
  const { enableCdsLogos } = useFlags();
  const currentUser = useCurrentUser();
  const allProjects = useMyProjects() ?? [];
  const { hasProjectBadge } = useProjectBadgeContext();

  const { getCounts } = useCommentContext();

  const hasMessages = interactions.some((interaction) => {
    const { pendingCount } = getCounts({
      angleId: interaction.angles?.[0]?.id,
      expertId: interaction.advisorId,
    });
    return pendingCount > 0;
  });

  const isCommentsEnabled =
    !!(!currentUser || allProjects.find(({ token }) => token === project.token)) &&
    hasProjectBadge(ENABLE_PORTAL_COMMENTS) &&
    project.active;

  const groupOf = (interaction: Interaction) => {
    const { group } = interaction;
    return group.parent ? group.parent.name : group.name;
  };

  const subGroupOf = (interaction: Interaction) => {
    const { group } = interaction;
    return group.parent ? group.name : null;
  };

  const sortTenure = (row1: ExpertTableRow, row2: ExpertTableRow) => {
    const period1 = getRolePeriodDuration(row1.original.rolePeriod);
    const period2 = getRolePeriodDuration(row2.original.rolePeriod);

    return period1 > period2 ? 1 : -1;
  };

  const sortLabels = (row1: ExpertTableRow, row2: ExpertTableRow) => {
    const expertId1 = row1.original.advisorId;
    const angle1 = row1.original.angles?.at(0);
    const angleId1 = angle1 && angle1?.id;
    const label1 = angleId1 ? getLabelledExpert(expertId1, angleId1) : undefined;
    const label1Text = label1?.label.text ?? "";

    const expertId2 = row2.original.advisorId;
    const angle2 = row2.original.angles?.at(0);
    const angleId2 = angle2 && angle2?.id;
    const label2 = angleId2 ? getLabelledExpert(expertId2, angleId2) : undefined;
    const label2Text = label2?.label.text ?? "";

    return label1Text.localeCompare(label2Text, undefined, { sensitivity: "accent" });
  };

  const sortStatus = (row1: ExpertTableRow, row2: ExpertTableRow) => {
    return statusOrder.indexOf(row1.original.state) > statusOrder.indexOf(row2.original.state) ? 1 : -1;
  };

  const sortMessageStatus = (row1: ExpertTableRow, row2: ExpertTableRow) => {
    const row1Status = getWorkRequestStatus({
      expertId: row1.original.advisorId,
    });
    const row2Status = getWorkRequestStatus({
      expertId: row2.original.advisorId,
    });

    return priority.indexOf(row2Status[0]?.state.description) > priority.indexOf(row1Status[0]?.state.description)
      ? 1
      : -1;
  };

  const defaultColumnConfig = {
    enablePin: preferencesEnabled,
    enableDragDrop: preferencesEnabled,
    sortAscMeta: {
      label: "Sort A-Z",
    },
    sortDescMeta: {
      label: "Sort Z-A",
    },
  };

  return {
    actionColumn: {
      id: "actionColumn",
      header: "",
      cell: ({ row: { original: interaction } }) => (
        <div className="action-column" data-testid={`action-column-${interaction.id}`}>
          <ActionColumn origin={origin} interaction={interaction} isMobile={isMobile} />
        </div>
      ),
      meta: {
        enableColumnActions: false,
        enableDragDrop: false,
        enableResizing: false,
      },
      size: 170,
    },

    groupColumn: {
      id: "groupColumn",
      header: "Angle",
      accessorFn: (row) => groupOf(row),
      sortingFn: "alphanumeric",
      size: 180,
      minSize: 94,
      cell: ({ row: { original: interaction } }) => {
        const groupText = groupOf(interaction);

        return (
          <HideableCell hidden={interaction.hidden}>
            <Tooltip title={groupText.length > 1 ? groupText : ""} enterDelay={10} leaveDelay={10}>
              <Typography
                variant="body-small"
                component="span"
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  display: "inline-block",
                  maxWidth: "100%",
                }}
                data-testid={`advisor-row-group-${interaction.id}`}
              >
                {groupText}
              </Typography>
            </Tooltip>
          </HideableCell>
        );
      },
      meta: { ...defaultColumnConfig },
    },

    subGroupColumn: {
      id: "subGroupColumn",
      header: "Sub-Angle",
      size: 180,
      minSize: 125,
      accessorFn: (row) => subGroupOf(row),
      sortingFn: "alphanumeric",
      cell: ({ row: { original: interaction } }) => {
        const subGroupText = subGroupOf(interaction);

        return (
          <HideableCell hidden={interaction.hidden}>
            <Tooltip title={(subGroupText?.length ?? 0) > 1 ? subGroupText : ""} enterDelay={10} leaveDelay={10}>
              <Typography
                variant="body-small"
                component="span"
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  display: "inline-block",
                  maxWidth: "100%",
                }}
                data-testid={`advisor-row-subgroup-${interaction.id}`}
              >
                {subGroupText ?? ""}
              </Typography>
            </Tooltip>
          </HideableCell>
        );
      },
      meta: { ...defaultColumnConfig },
    },

    expertColumn: {
      id: "advisorColumn",
      header: "Expert",
      minSize: 94,
      cell: AdvisorColumn,
      accessorKey: "advisorName",
      sortingFn: "alphanumeric",
      meta: { ...defaultColumnConfig },
    },

    cidStatusColumn: {
      id: "cidColumn",
      header: "CID Status",
      accessorFn: (row) => row.externalCidStatus,
      sortingFn: "alphanumeric",
      minSize: 125,
      size: 125,
      cell: ({ row: { original: interaction } }) => {
        return (
          <HideableCell hidden={false}>
            <CidBadge
              userCidEnabled={userCidEnabled}
              externalCidStatus={interaction.externalCidStatus}
              useFullWidth
            ></CidBadge>
          </HideableCell>
        );
      },
      meta: { ...defaultColumnConfig },
    },

    companyColumn: {
      id: "companyColumn",
      header: "Relevant Company",
      accessorKey: "advisorCompany",
      sortingFn: "alphanumeric",
      minSize: enableCdsLogos ? 216 : 176,
      cell: RelevantCompanyColumn,
      meta: { ...defaultColumnConfig },
    },

    relevantPositionColumn: {
      id: "positionColumn",
      header: "Relevant Position",
      accessorKey: "role",
      sortingFn: "alphanumeric",
      minSize: 184,
      cell: ({
        row: {
          original: { id, role, hidden, highlights, angles, advisorId },
        },
      }) => (
        <HideableCell hidden={hidden}>
          <HighlightTextTypography
            data-testid={`advisor-row-role-${id}`}
            variant="body-small"
            component="span"
            highlights={highlights}
            fieldNames={[
              "advisorships.relevant_job_title",
              "advisorships.relevant_job_title.concat",
              "relevantPrimaryJob.jobTitle",
              "relevantPrimaryJob.jobTitle.concat",
            ]}
            text={role}
            preventLineBreak
          />
        </HideableCell>
      ),
      meta: { ...defaultColumnConfig },
    },

    tenureColumn: {
      id: "tenureColumn",
      header: "Tenure",
      accessorKey: "rolePeriod",
      sortingFn: sortTenure,
      minSize: 150,
      cell: ({ row: { original: interaction } }) => (
        <HideableCell hidden={interaction.hidden}>
          <Typography variant="body-small" component="span">
            {interaction.rolePeriod}
          </Typography>
        </HideableCell>
      ),
      meta: {
        ...defaultColumnConfig,
        sortAscMeta: {
          label: "Sort Shortest-Longest",
        },
        sortDescMeta: {
          label: "Sort Longest-Shortest",
        },
      },
    },

    geographicKnowledgeColumn: {
      id: "geographicKnowledgeColumn",
      header: "Geographic Knowledge",
      hideIf: ({ hasColumnPreferences }) => !hasColumnPreferences || !interactions.some((i) => i.geographies.length),
      minSize: 210,
      sortingFn: "alphanumeric",
      cell: ({ row: { original: interaction } }) => (
        <HideableCell hidden={interaction.hidden}>
          <Tooltip
            title={interaction.geographies.length > 1 ? interaction.geographies.join(", ") : ""}
            enterDelay={10}
            leaveDelay={10}
          >
            <Typography
              variant="body-small"
              component="span"
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                display: "inline-block",
                maxWidth: "100%",
              }}
            >
              {interaction.geographies.join(", ")}
            </Typography>
          </Tooltip>
        </HideableCell>
      ),
      meta: { ...defaultColumnConfig },
    },

    statusColumn: {
      id: "statusColumn",
      header: "Call Status",
      accessorKey: "state",
      sortingFn: sortStatus,
      minSize: 220,
      cell: StatusColumn,
      meta: {
        ...defaultColumnConfig,
        sortAscMeta: {
          label: "Sort First to Last",
        },
        sortDescMeta: {
          label: "Sort Last to First ",
        },
      },
    },

    labelColumn: {
      id: "labelColumn",
      header: "Label",
      accessorKey: "label",
      sortingFn: sortLabels,
      minSize: 220,
      cell: LabelColumn,
      meta: {
        ...defaultColumnConfig,
        columnDescription: "Labels are visible to your colleagues and your AlphaSights team.",
        sortAscMeta: {
          label: "Sort A-Z",
        },
        sortDescMeta: {
          label: "Sort Z-A",
        },
      },
      visible: interactions.some((interaction) => {
        const expertId = interaction.advisorId;
        const angleId = interaction.angles?.[0]?.id;
        return angleId && getLabelledExpert(expertId, angleId)?.label;
      }),
    },

    messageStatusColumn: {
      id: "messageStatusColumn",
      header: "Message Status",
      accessorKey: "state",
      sortingFn: sortMessageStatus,
      minSize: 260,
      cell: MessageStatusColumn,
      meta: {
        ...defaultColumnConfig,
        sortAscMeta: {
          label: "Sort First to Last",
        },
        sortDescMeta: {
          label: "Sort Last to First ",
        },
      },
    },

    showProfileColumn: {
      id: "showProfile",
      header: "",
      size: 52,
      minSize: 52,
      enableResizing: false,
      cell: ({ row: { original: data } }) => (
        <IconButton variant="ghost" onClick={() => onSelectCard(data)}>
          <Advisor />
        </IconButton>
      ),
      columnName: "Show Profile",
      meta: { ...defaultColumnConfig },
    },

    commentsColumn: {
      id: "commentsColumn",
      header: "Comments",
      enableResizing: false,
      cell: ({ row: { original: data } }) => <NewCommentIconWithLoginWall interaction={data} />,
      size: 150,
      minSize: 150,
      columnName: "Comments",
      meta: {
        ...defaultColumnConfig,
        enableSorting: false,
      },
      visible: hasMessages && !isFeatureDisabled(ProjectFeature.Comments) && isCommentsEnabled,
    },

    proposedAtColumn: {
      id: "proposedAtColumn",
      header: "Date Added",
      accessorKey: "proposedAt",
      minSize: 130,
      cell: ({ row: { original: data } }) => {
        return <FormattedDateTime date={data.proposedAt} format="d LLL, h:mmaaa" />;
      },
      size: 45,
      meta: {
        ...defaultColumnConfig,
        sortAscMeta: {
          label: "Sort Oldest to Newest",
        },
        sortDescMeta: {
          label: "Sort Newest to Oldest ",
        },
      },
    },

    interactionDetails: {
      id: "interactionDetailsColumn",
      header: "Interaction Details",
      accessorKey: "interactionDetails",
      minSize: 195,
      cell: InteractionDetailsColumn,
      meta: {
        ...defaultColumnConfig,
        enableSorting: false,
      },
    },
  };
};

const filterColumnDefinitions = ({
  columns,
  project,
  isProjectCidEnabled,
  isMobile,
  isCustomerKnowledgeEnabled,
  threadHeaders,
  interactions,
  isPastProjectDeliverablesEnabled,
  isInteractionActionsDisabled,
}: {
  columns: { [key: string]: ExpertTableColumnDefinition };
  project: Project;
  isProjectCidEnabled: boolean;
  isMobile: boolean;
  isCustomerKnowledgeEnabled?: boolean;
  threadHeaders: MessageThreadHeaderResponse[];
  interactions: Interaction[];
  isPastProjectDeliverablesEnabled: boolean;
  isInteractionActionsDisabled: boolean;
}): ExpertTableColumnDefinition[] => {
  const columnDefs = [];

  if (project.active && !isInteractionActionsDisabled) {
    columnDefs.push(columns.actionColumn);
  }

  if (project.inactive && isPastProjectDeliverablesEnabled) {
    columnDefs.push(columns.interactionDetails);
  }

  if (columns.labelColumn.visible) {
    columnDefs.push(columns.labelColumn);
  }

  if (columns.commentsColumn.visible) {
    columnDefs.push(columns.commentsColumn);
  }

  if (isCustomerKnowledgeEnabled) {
    columnDefs.push(columns.currentVendorColumn);
    columnDefs.push(columns.otherVendorsColumn);
    columnDefs.push(columns.roleColumn);
  }

  columnDefs.push(columns.groupColumn);

  if (interactions.some((i) => i.hasSubGroup)) {
    columnDefs.push(columns.subGroupColumn);
  }

  columnDefs.push(columns.expertColumn);

  if (project.active && isProjectCidEnabled) columnDefs.push(columns.cidStatusColumn);

  columnDefs.push(columns.companyColumn);
  columnDefs.push(columns.relevantPositionColumn);
  columnDefs.push(columns.tenureColumn);

  if (project.active) {
    columnDefs.push(columns.statusColumn);
    columnDefs.push(columns.proposedAtColumn);
  }

  if (project.enablePortalMessages && threadHeaders.length > 0) {
    columnDefs.push(columns.messageStatusColumn);
  }

  if (project.active) {
    columnDefs.push(columns.geographicKnowledgeColumn);
  }

  if (isMobile) columnDefs.push(columns.showProfileColumn);

  return columnDefs;
};

const NewCommentIconWithLoginWall = withLoginWall(NewCommentIcon);
export { useBuildColumnDefinitions, filterColumnDefinitions };
